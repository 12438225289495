import { useMemo } from "react";
import { CellClassParams, ColDef, ColGroupDef } from "ag-grid-community";
import { useParams } from "react-router";
import {
  coldefDateField,
  coldefHiddenField,
  coldefNavigation,
  coldefSingleField,
  coldefUser
} from "entity-app/shared-components/log-render-components/col-defs";
import {
  ArrayStringCellRenderer,
  ArrayStringGetQuickFilterText,
  featureStatusCellRenderer,
  twoRowCellRenderer,
  twoRowGetQuickFilterText
} from "entity-app/shared-components/log-render-components/cell-renders";
import {
  bidPackageDueDateHeaderComponent,
  CellCurrencyRendererWithEditIcon,
  CellValueRendererWithEditIcon,
  dueDateCellRenderer,
  nextDeadlineCellRenderer,
  riskCellRenderer
} from "components/cell-renders";
import numberCellEditor from "components/cell-editor/numberCellEditor";
import currencyCellEditor from "components/cell-editor/currency-cell-editor";
import { EWorkflowStatusDataBlock } from "constants/index";
import { FeatureTypes } from "entity-app/constants";
import DropdownMultiSelectCellEditor from "components/cell-editor/drop-down-multi-select-editor";
import { TBidPackageLog } from "../model";

export const usePreconstrutionAgGrid = (props: {
  featureKey: string;
  milestonesColumns: any;
  cannotWriteAccessOfBidPackage: boolean;
  bidPackageHeader: any;
}) => {
  const {
    featureKey,
    milestonesColumns,
    cannotWriteAccessOfBidPackage,
    bidPackageHeader
  } = props;

  const { projectId } = useParams() as any;
  const agGridCheckFieldEditable = (params: CellClassParams<any, any>) => {
    const { data, colDef } = params;
    const colId = colDef.colId || "";
    const fieldEditOnlyWhenNotStarted = [
      "pre_WF_start_float",
      "bid_package_template_id"
    ];
    if (
      fieldEditOnlyWhenNotStarted.includes(colId) &&
      data.workflow_status !== EWorkflowStatusDataBlock.WF_NOT_STARTED
    )
      return false;
    return true;
  };

  const isGridCellEditable = (params: CellClassParams<any, any>) => {
    return (
      !cannotWriteAccessOfBidPackage &&
      params?.colDef?.editable &&
      agGridCheckFieldEditable(params)
    );
  };

  const defaultColDef = {
    sortable: true,
    editable: false,
    filter: true,
    resizable: true,
    menuTabs: ["filterMenuTab"],
    minWidth: 100,
    cellRenderer: CellValueRendererWithEditIcon,
    cellEditorPopup: false
  } as ColDef<TBidPackageLog>;

  const columnDefs = useMemo(
    () =>
      [
        coldefNavigation(
          {
            field: "auto_inc_value",
            headerName: bidPackageHeader?.id,
            pinned: "left",
            lockPosition: "left",
            checkboxSelection: true,
            width: 120,
            sort: "asc",
            lockVisible: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true
          },
          projectId,
          featureKey
        ),
        coldefSingleField({
          field: "number",
          headerName: bidPackageHeader?.number,
          pinned: "left",
          lockPosition: "left",
          width: 120,
          editable: true,
          cellEditorParams: {
            key: "number"
          }
        }),
        coldefSingleField({
          field: "title",
          headerName: bidPackageHeader?.title,
          width: 180,
          pinned: "left",
          lockPosition: "left",
          cellRenderer: twoRowCellRenderer,
          cellRendererParams: {
            featureKey,
            projectId
          },
          getQuickFilterText: twoRowGetQuickFilterText,
          lockVisible: true,
          editable: true
        }),
        coldefHiddenField({
          field: "description",
          headerName: bidPackageHeader?.description,
          pinned: "left",
          lockPosition: "left"
        }),
        coldefSingleField({
          field: "wave",
          headerName: bidPackageHeader?.wave,
          width: 100,
          editable: true
        }),
        coldefSingleField({
          field: "bid_scope",
          headerName: bidPackageHeader?.bid_scope,
          getQuickFilterText: ArrayStringGetQuickFilterText,
          cellRenderer: ArrayStringCellRenderer,
          cellRendererParams: {
            // featureKey
          },
          valueGetter: ({ data }) => {
            if (!data || !data.bid_scope) return [];
            return data.bid_scope.map((scope: any) => ({
              id: scope.id || scope.spec_section_id,
              name: scope.name
                ? scope.name
                : `${scope.spec_section_number} - ${scope.spec_section_title}`
            }));
          },
          tooltipField: undefined,
          editable: true,
          cellEditor: DropdownMultiSelectCellEditor,
          cellEditorParams: {
            dropdownType: "spec_dropdown"
          },
          menuTabs: [],
          width: 300,
          minWidth: 300
        }),
        coldefSingleField({
          field: "milestone_state",
          headerName: bidPackageHeader?.workflow_status,
          lockVisible: true,
          width: 200,
          minWidth: 200,
          tooltipField: undefined,
          tooltipValueGetter: undefined,
          editable: true,
          cellRenderer: featureStatusCellRenderer
        }),
        coldefDateField({
          field: "next_planned_deadline",
          headerName: bidPackageHeader?.next_deadline,
          cellRenderer: nextDeadlineCellRenderer
        }),
        coldefDateField({
          field: "due_date",
          headerName: bidPackageHeader?.due_date,
          cellRenderer: dueDateCellRenderer,
          headerComponentParams: bidPackageDueDateHeaderComponent
        }),
        coldefSingleField({
          field: "risk",
          headerName: bidPackageHeader?.risk_level,
          width: 130,
          cellRenderer: riskCellRenderer
        }),
        coldefSingleField({
          field: "pre_WF_start_float",
          headerName: "BID PACKAGE PLANNED FLOAT",
          width: 130,
          editable: true,
          cellEditor: numberCellEditor
        }),
        coldefSingleField({
          field: "effective_float",
          headerName: "BID PACKAGE ACTUAL FLOAT",
          width: 130
        }),
        coldefUser({
          def: {
            field: "awarded_subcontractor_name",
            headerName: bidPackageHeader?.awarded_subcontractor_name,
            editable: true
          },
          user: {
            id: "awarded_subcontractor_id",
            firstName: "awarded_subcontractor_name",
            lastName: "",
            dropdownType: "RC_dropdown"
          }
        }),
        coldefSingleField({
          field: "awarded_value",
          headerName: bidPackageHeader?.awarded_value,
          width: 160,
          cellRenderer: CellCurrencyRendererWithEditIcon,
          editable: true,
          cellEditor: currencyCellEditor
        }),
        coldefSingleField({
          field: "estimated_value",
          headerName: bidPackageHeader?.estimated_value,
          width: 160,
          cellRenderer: CellCurrencyRendererWithEditIcon,
          editable: true,
          cellEditor: currencyCellEditor
        }),
        coldefUser({
          def: {
            headerName: bidPackageHeader?.preconstruction_lead,
            editable: true
          },
          user: {
            id: "preconstruction_lead_id",
            firstName: "preconstruction_lead_first_name",
            lastName: "preconstruction_lead_last_name",
            statusId: "preconstruction_lead_status_id"
          }
        }),
        coldefUser({
          def: {
            headerName: bidPackageHeader?.operations_lead,
            editable: true
          },
          user: {
            id: "operations_lead_id",
            firstName: "operations_lead_first_name",
            lastName: "operations_lead_last_name",
            statusId: "operations_lead_status_id"
          }
        }),
        coldefUser({
          def: {
            field: "bid_package_template_name",
            headerName: bidPackageHeader?.workflow_template_id,
            editable: true
          },
          user: {
            id: "bid_package_template_id",
            firstName: "bid_package_template_name",
            lastName: "",
            dropdownType: "Template_dropdown",
            featureTypeId: FeatureTypes.BID_PACKAGE
          }
        }),
        coldefSingleField({
          field: "no_of_bidders",
          headerName: bidPackageHeader?.no_of_bidders,
          width: 160,
          editable: true,
          cellEditor: numberCellEditor
        }),
        coldefSingleField({
          field: "bidding_subcontractors",
          headerName: bidPackageHeader?.bidding_subcontractors,
          getQuickFilterText: ArrayStringGetQuickFilterText,
          cellRenderer: ArrayStringCellRenderer,
          cellRendererParams: {
            // featureKey
          },
          cellEditor: DropdownMultiSelectCellEditor,
          tooltipField: undefined,
          menuTabs: [],
          width: 320,
          minWidth: 310,
          editable: true
        }),
        ...milestonesColumns.allColumns
      ] as (ColDef<TBidPackageLog> | ColGroupDef<TBidPackageLog>)[],
    [projectId, featureKey, milestonesColumns.allColumns, bidPackageHeader]
  );
  return {
    columnDefs,
    defaultColDef,
    milestonesColumns,
    isGridCellEditable
  };
};
