import {
  useContext,
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback
} from "react";
import RenamePopup, {
  InputEditDataType
} from "popups/rename-model/rename-popup";
import { useMutation } from "@apollo/client";
import {
  ProjectContext,
  isPermissionNotGrantted
} from "context/ProjectProvider";
import { Button, message } from "antd";
import {
  DATEBLOCK_ACTIONS,
  GET_MDB_TEMPLATES
} from "services/graphQL/ciq-queries";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { TAuditChangeLog } from "change-events/change-event-polling";
import { getDaysText } from "utils/utils";
import {
  CalendarOutlined,
  CarryOutOutlined,
  EditOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import Tooltip from "antd/es/tooltip";
import { DateUtils } from "utils/dateutils";
import DatePickerWithIcon from "components/date-picker-with-icon/date-picker-with-icon";
import {
  ErrorMessages,
  EWorkflowStatusDataBlock,
  ProjectPermissionEnum
} from "constants/index";
import DateBlockCard from "./date-block-card";
import {
  MUTATION_UPDATE_DATEBLOCK_PK,
  QUERY_AGG_SUBMITTAL_STATUS_LINKED_BY_MATERIAL_ID
} from "./gql-graphgl";
import {
  DateBlockInfoType,
  GoverningTaskType,
  RiskLevelType,
  TDateBlockType,
  TEnableActualReleaseDate,
  TNewDateBlock,
  TProjectTemplateMilestones,
  TProjectWorkflowTemplates
} from "./models";
import {
  transformMaterialDateBlock,
  transformSubmittalDateBlock
} from "./data-transformations";
import RiskStatementView from "./risk-statement";
import "./style.scss";

type TProps = {
  dateblock: TDateBlockType;
  governingTask?: GoverningTaskType;
  disableDB: boolean;
  enableActualReleaseDate?: TEnableActualReleaseDate;
  componentLocation?: "MaterialDetail" | "SubmittalDetail" | "links";
  isSubmittal: boolean;
  additionalMaterialOffset?: boolean;
  projectTemplateMilestones?: Array<TProjectTemplateMilestones>;
  governingSource: { name?: string; type?: string } | null;
};

function DateBlockUI(props: TProps) {
  const {
    dateblock,
    governingTask,
    disableDB,
    enableActualReleaseDate,
    governingSource,
    componentLocation,
    isSubmittal,
    additionalMaterialOffset = true,
    projectTemplateMilestones = []
  } = props;

  const { gqlClientForProject, tokenContents, eventLogs } =
    useContext(ProjectContext);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [renameDataInput, setRenameDataInput] = useState<InputEditDataType>({
    value: "",
    id: ""
  });

  const { data: dateBlockInfoFeatureData, refetch: refecDateblockActionInfo } =
    useCIQQuery<{
      project_feature_configurations: Array<DateBlockInfoType>;
    }>(DATEBLOCK_ACTIONS, {
      client: gqlClientForProject,
      skip: !gqlClientForProject,
      variables: { where: { feature_id: { _eq: 1 } } }
    });

  const previousEventLogs = useRef(eventLogs);
  useEffect(() => {
    if (eventLogs.length && previousEventLogs.current !== eventLogs) {
      if (
        eventLogs.some(
          (e) => e.data_source === "project_feature_configurations"
        )
      ) {
        refecDateblockActionInfo();
      }
    }
    previousEventLogs.current = eventLogs;
  }, [refecDateblockActionInfo, eventLogs]);

  const [updateDateBlockMutationPK] = useMutation(
    MUTATION_UPDATE_DATEBLOCK_PK,
    {
      client: gqlClientForProject
    }
  );

  const showMutationResult = async (
    mutationFunction: Function,
    variables: any
  ) => {
    try {
      const res = await mutationFunction({
        variables
      });

      if (res.data) {
        message.success("Updated successfully.");
      }
      if (res.errors) {
        message.error(res.errors[0].message);
      }
    } catch (ex: any) {
      message.error(ex.message);
    }
  };

  const editDateBlock = async (set: any = {}) => {
    const variables: any = { id: dateblock.id, set };
    await showMutationResult(updateDateBlockMutationPK, variables);
  };

  const editOffsetDateBlock = async (newOffset: string, id: string) => {
    const set = {} as any;
    set[id] = newOffset;
    if (!isSubmittal) set.template_value_overidden = true;
    return editDateBlock(set);
  };

  const isSubmittalWorkflowStarted = !!dateblock.mileStones[0]?.actual;

  /**
   * A memoized function that finds the last incomplete milestone from the given dateblock milestones.
   * It reverses the milestones array and iterates through it to find the last milestone that is either:
   * - The milestone before the first milestone with an actual date.
   * - The first milestone with a sequence number of 1 and a planned date.
   *
   * @returns {Milestone | null} The last incomplete milestone or null if no such milestone is found.
   */
  const lastIncompleteMilestone = useMemo(() => {
    const reversedMilestones = [...dateblock.mileStones].reverse();
    for (let i = 0; i < reversedMilestones.length; i += 1) {
      if (reversedMilestones[i].actual) {
        return reversedMilestones[i - 1] || null;
      }

      if (
        reversedMilestones[i].sequence_no === 1 &&
        reversedMilestones[i].planned !== null
      ) {
        return reversedMilestones[i];
      }
    }
    return null;
  }, [dateblock.mileStones]);

  const isPermissionNotGrantedForOffset =
    disableDB ||
    isPermissionNotGrantted(
      ProjectPermissionEnum.EditMaterialDBOffset,
      tokenContents?.role!
    );

  const cannotChangePlannedDate =
    disableDB ||
    isPermissionNotGrantted(
      ProjectPermissionEnum.changeMaterialPlannedDate,
      tokenContents?.role!
    );
  const cannotChangeActualDate =
    disableDB ||
    isPermissionNotGrantted(
      ProjectPermissionEnum.changeMaterialActualDate,
      tokenContents?.role!
    );

  const cannotChangeProjectedDate =
    disableDB ||
    isPermissionNotGrantted(
      ProjectPermissionEnum.changeProjectedDate,
      tokenContents?.role!
    );

  const childRefPlannedDate = useRef<any>();
  const renderExtraFooterDiv = (
    <div className="flex justify-center py-2">
      <Button
        disabled={cannotChangePlannedDate}
        onClick={() => {
          childRefPlannedDate.current?.close();
          const updates = { manual_entry_date: null };
          editDateBlock(updates);
        }}
      >
        Clear Date
      </Button>
    </div>
  );

  return (
    <div className="flex items-center relative pt-16">
      {dateblock.mileStones.map((block, index) => (
        <DateBlockCard
          key={block.key}
          dateblock={dateblock}
          block={block}
          previousBlock={index === 0 ? null : dateblock.mileStones[index - 1]}
          nextBlock={
            index === dateblock.mileStones.length - 1
              ? null
              : dateblock.mileStones[index + 1]
          }
          isFirstCard={index === 0}
          isLastCard={index === dateblock.mileStones.length - 1}
          isSubmittal={isSubmittal}
          setIsRenameModalOpen={setIsRenameModalOpen}
          setRenameDataInput={setRenameDataInput}
          editDateBlock={editDateBlock}
          governingTask={governingTask}
          enableActualReleaseDate={enableActualReleaseDate}
          isSubmittalWorkflowStarted={isSubmittalWorkflowStarted}
          componentLocation={componentLocation}
          additionalMaterialOffset={additionalMaterialOffset}
          projectTemplateMilestones={projectTemplateMilestones}
          dateBlockInfoFeatureData={dateBlockInfoFeatureData}
          lastIncompleteMilestone={lastIncompleteMilestone}
          isPermissionNotGrantedForOffset={isPermissionNotGrantedForOffset}
          cannotChangeActualDate={cannotChangeActualDate}
          cannotChangeProjectedDate={cannotChangeProjectedDate}
        />
      ))}
      <div className="w-52 h-52 flex-col space-y-3 text-xs text-[#3B3B3B]">
        <div className="w-full bg-[#F7F6F4] p-1">
          <div className="flex justify-between p-1 items-center">
            <div>Planned Float</div>
            <div className="font-semibold space-x-1 flex items-center">
              <div>
                {dateblock.planned_float} {getDaysText(dateblock.planned_float)}
              </div>
              {!isPermissionNotGrantedForOffset &&
                !isSubmittalWorkflowStarted &&
                !cannotChangePlannedDate && (
                  <EditOutlined
                    className="pl-1"
                    onClick={() => {
                      setRenameDataInput({
                        value: dateblock.planned_float,
                        id: "pre_WF_start_float",
                        lable: "Planned Float",
                        type: "text"
                      });
                      setIsRenameModalOpen(true);
                    }}
                  />
                )}
            </div>
          </div>
          <div className="flex justify-between p-1 items-center">
            <div>Actual Float</div>
            <div
              className={
                dateblock.risk_level === RiskLevelType.High
                  ? "date-block-risk-high font-semibold"
                  : "font-semibold"
              }
            >
              {dateblock.actual_float !== null
                ? `${dateblock.actual_float} ${getDaysText(
                    dateblock.actual_float
                  )}`
                : " - "}
            </div>
          </div>
        </div>
        <div className="w-full p-1 bg-[#F7F6F4]">
          <div className="flex justify-between p-1 items-baseline">
            <div>Deadline</div>
            <div className="font-semibold flex space-x-1 items-center">
              {dateblock.final_deadline_date ? (
                <div>{DateUtils.format(dateblock.final_deadline_date)}</div>
              ) : (
                <div className="px-1">-</div>
              )}

              {dateblock.final_deadline_date && (
                <Tooltip
                  title={
                    governingSource ? (
                      <div className="p-2">
                        <div className="text-[#8E8E8E]">Source:</div>
                        <div>{governingSource.type}</div>
                        <div>({governingSource.name})</div>
                      </div>
                    ) : (
                      ""
                    )
                  }
                >
                  <InfoCircleOutlined />
                </Tooltip>
              )}

              <div className="mb-1 flex">
                <DatePickerWithIcon
                  title={
                    cannotChangePlannedDate
                      ? ErrorMessages.PermissionNotGranted
                      : "Set date to be no later than"
                  }
                  onChange={(date) => {
                    if (date) {
                      const saveDate = DateUtils.formatDateWithLunchTime(date);
                      const updates = { manual_entry_date: saveDate };
                      editDateBlock(updates);
                    }
                  }}
                  value={
                    dateblock.manual_entry_date
                      ? DateUtils.dateTimeObj(dateblock.manual_entry_date)
                      : undefined
                  }
                  disabledDate={() => {
                    return (
                      dateblock.workflow_status ===
                        EWorkflowStatusDataBlock.WF_COMPLETED ||
                      cannotChangePlannedDate
                    );
                  }}
                  ref={childRefPlannedDate}
                  renderExtraFooter={() => {
                    return dateblock.workflow_status !==
                      EWorkflowStatusDataBlock.WF_COMPLETED &&
                      dateblock.manual_entry
                      ? renderExtraFooterDiv
                      : undefined;
                  }}
                  icon={
                    dateblock.manual_entry ? (
                      <CarryOutOutlined />
                    ) : (
                      <CalendarOutlined />
                    )
                  }
                />
                {governingSource?.name === "Manually Set Date" && (
                  <div className="-ml-0.5">*</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isRenameModalOpen && (
        <RenamePopup
          isModalOpen={isRenameModalOpen}
          setIsModalOpen={setIsRenameModalOpen}
          inputData={renameDataInput}
          onOk={editOffsetDateBlock}
          title="Edit duration"
          okName="Save"
        />
      )}
    </div>
  );
}

function DateBlockDynamicUIPanel(props: {
  submittal:
    | {
        date_block_submittals: Array<TNewDateBlock>;
        material_tracking: boolean;
        submittal_material_links?: Array<any>;
        submittal_schedule_links?: Array<any>;
      }
    | undefined;
  material:
    | {
        id: any;
        date_block_materials: Array<TNewDateBlock>;
        material_schedule_links?: Array<any>;
        submittal_material_links?: Array<any>;
      }
    | undefined;
  submittalTitle: any;
  materialTitle: any;
  noMaterialDateBlockDiv: any;
  noSubmittalDateBlockDiv: any;
  governingTaskOfMaterial?: GoverningTaskType;
  governingTaskOfSubmittal?: GoverningTaskType;
  disableDB?: boolean;
  componentLocation?: "MaterialDetail" | "SubmittalDetail" | "links";
}) {
  const {
    submittal,
    material,
    submittalTitle,
    materialTitle,
    noMaterialDateBlockDiv,
    noSubmittalDateBlockDiv,
    governingTaskOfMaterial,
    governingTaskOfSubmittal,
    disableDB,
    componentLocation
  } = props;
  const { gqlClientForProject, tokenContents, projectDetails, eventLogs } =
    useContext(ProjectContext);

  const { data: MDBTemplates, refetch: refetchMDB } = useCIQQuery<{
    project_workflow_templates: Array<TProjectWorkflowTemplates>;
  }>(GET_MDB_TEMPLATES, {
    client: gqlClientForProject
  });

  const materialDateBlock: any = useMemo(() => {
    return projectDetails &&
      material &&
      material.date_block_materials?.length > 0
      ? transformMaterialDateBlock(material.date_block_materials[0])
      : undefined;
  }, [material, projectDetails]);

  const { data: linkedSubmittalStatus, refetch: refetchLinkedSubmittalStatus } =
    useCIQQuery<{
      submittals_aggregate: { nodes: Array<{ status: number }> };
    }>(QUERY_AGG_SUBMITTAL_STATUS_LINKED_BY_MATERIAL_ID, {
      client: gqlClientForProject,
      skip: !gqlClientForProject || !materialDateBlock,
      variables: {
        materialId: materialDateBlock?.material_id
      }
    });

  const handlelinkedSubmittalStatus = useCallback(
    (logs: TAuditChangeLog[]) => {
      if (
        material &&
        material.id &&
        logs.some(
          (x) =>
            x.info.material_ids?.length &&
            x.info.material_ids.includes(material.id)
        )
      ) {
        refetchLinkedSubmittalStatus();
      } else if (
        material &&
        material.submittal_material_links &&
        material.submittal_material_links.length
      ) {
        const linkedSubmittalIds = material.submittal_material_links.map(
          (s) => s.submittal_id
        );

        if (
          logs.some(
            (x) =>
              x.info.submittal_ids?.length &&
              x.info.submittal_ids?.some((l) => linkedSubmittalIds.includes(l))
          )
        ) {
          refetchLinkedSubmittalStatus();
        }
      }
    },
    [material, refetchLinkedSubmittalStatus]
  );

  const previousEventLogs = useRef(eventLogs);
  useEffect(() => {
    if (eventLogs?.length && previousEventLogs.current !== eventLogs) {
      handlelinkedSubmittalStatus(eventLogs);
      if (
        eventLogs.some((x) => x.data_source === "project_workflow_templates")
      ) {
        refetchMDB(); // Refetching Material Template data
      }
    }
    previousEventLogs.current = eventLogs;
  }, [eventLogs, refetchMDB, handlelinkedSubmittalStatus]);

  const activeTemplateDetails = useMemo(() => {
    if (!MDBTemplates) return undefined;
    let activeTemplateId =
      material?.date_block_materials[0].workflow_template_id;

    if (!activeTemplateId) {
      const defaultTemplate = MDBTemplates.project_workflow_templates.find(
        (template: any) => {
          return template.default;
        }
      );
      activeTemplateId = defaultTemplate!.id;
    }

    return MDBTemplates.project_workflow_templates.find((template: any) => {
      return activeTemplateId === template.id;
    });
  }, [MDBTemplates, material?.date_block_materials]);

  const submittalDateBlock = useMemo(() => {
    return projectDetails &&
      submittal &&
      submittal.date_block_submittals?.length > 0
      ? transformSubmittalDateBlock(submittal.date_block_submittals[0])
      : undefined;
  }, [submittal, projectDetails]);

  const isPermissionNotGrantedForSubmittal =
    disableDB ||
    isPermissionNotGrantted(
      ProjectPermissionEnum.EditDateBlockOffsetSubmittal,
      tokenContents?.role!
    );

  const isPermissionNotGrantedForMaterial =
    disableDB ||
    isPermissionNotGrantted(
      ProjectPermissionEnum.EditDateBlockOffsetMaterial,
      tokenContents?.role!
    );

  const [enableActualReleaseDate, setEnableActualReleaseDate] =
    useState<TEnableActualReleaseDate>({
      enable: true,
      message: "",
      isAnySubmittalStarted: false
    });

  useEffect(() => {
    if (linkedSubmittalStatus?.submittals_aggregate) {
      const submittalsList = linkedSubmittalStatus?.submittals_aggregate.nodes;
      const isAnySubmittalNotClosed = submittalsList.some(
        (e) => e.status !== 10
      );
      const isAnySubmittalStarted = submittalsList.some((e) => e.status !== 1);

      setEnableActualReleaseDate({
        enable: !isAnySubmittalNotClosed,
        message: isAnySubmittalNotClosed
          ? "Associated submittals are not closed."
          : "",
        isAnySubmittalStarted
      });
    }
  }, [linkedSubmittalStatus]);

  const governingSourceForSubmittal = useMemo(() => {
    const drivingMaterial = submittal?.submittal_material_links?.find(
      (mat: any) => mat?.driving_material
    );

    if (drivingMaterial) {
      let materialName = "";
      if (drivingMaterial.implicit) {
        materialName = `Material for Submittal ${drivingMaterial?.submittal_sequence_id}`;
      } else {
        materialName = `${drivingMaterial.material_sequence_id}-${drivingMaterial.material_name}`;
      }
      return {
        type: "Linked Material",
        name: materialName
      };
    }

    const drivingTask = submittal?.submittal_schedule_links?.find(
      (tsk: any) => tsk?.driving_task
    );
    if (drivingTask) {
      const task = drivingTask?.gantt_task;
      return {
        type: "Linked Activity",
        name: `${task?.source_task_id} ${task?.text}`
      };
    }

    if (submittalDateBlock?.manual_entry) {
      return {
        name: "Manually Set Date"
      };
    }

    return null;
  }, [
    submittal?.submittal_material_links,
    submittal?.submittal_schedule_links,
    submittalDateBlock?.manual_entry
  ]);

  const governingSourceForMaterial = useMemo(() => {
    const drivingTask = material?.material_schedule_links?.find(
      (tsk: any) => tsk?.driving_task
    );
    if (drivingTask) {
      const task = drivingTask?.gantt_task;
      return {
        type: "Linked Activity",
        name: `${task?.source_task_id} ${task?.text}`
      };
    }
    if (materialDateBlock?.manual_entry) {
      return {
        name: "Manually Set Date"
      };
    }
    return null;
  }, [material?.material_schedule_links, materialDateBlock?.manual_entry]);

  return (
    <div className="flex w-full h-full date-block-component">
      {submittalDateBlock ? (
        <div className="pr-4">
          <div className="flex w-full h-10">{submittalTitle}</div>
          {componentLocation && (
            <div className="flex h-6">
              {componentLocation === "SubmittalDetail" && (
                <RiskStatementView
                  disabled={isPermissionNotGrantedForSubmittal}
                  dateBlocks={submittal?.date_block_submittals}
                />
              )}
            </div>
          )}
          <div>
            <DateBlockUI
              key="submittalDateBlock"
              dateblock={submittalDateBlock}
              governingTask={governingTaskOfSubmittal}
              disableDB={isPermissionNotGrantedForSubmittal}
              governingSource={governingSourceForSubmittal}
              componentLocation={componentLocation}
              isSubmittal
              additionalMaterialOffset={
                activeTemplateDetails?.additional_offset
              }
            />
          </div>
        </div>
      ) : (
        <div>{noSubmittalDateBlockDiv}</div>
      )}
      {materialDateBlock && submittalDateBlock && (
        <div className="pr-3">
          <div className="h-[7.25rem] w-1" />
          {componentLocation && <div className="flex h-6" />}
          <div className="flex h-0">
            <div className="relative w-0 h-0">
              <div className="absolute w-24 -left-24 border-0 border-t border-solid shrink-0 grow" />
              <div className="absolute w-0 left-0 h-28 -top-10 border-0 border-r border-solid shrink-0 grow" />
              <div className="absolute w-20 border-0 border-t border-solid shrink-0 grow" />
            </div>
          </div>
        </div>
      )}
      {materialDateBlock ? (
        <div className="flex">
          <div className="pr-2">
            <div className="flex w-full h-10">{materialTitle}</div>
            {componentLocation && (
              <div className="flex h-6">
                {componentLocation === "MaterialDetail" && (
                  <RiskStatementView
                    disabled={isPermissionNotGrantedForMaterial}
                    dateBlocks={material?.date_block_materials}
                  />
                )}
              </div>
            )}
            <div className="flex">
              <DateBlockUI
                key="materialDateBlock"
                dateblock={materialDateBlock}
                governingTask={governingTaskOfMaterial}
                disableDB={isPermissionNotGrantedForMaterial}
                enableActualReleaseDate={enableActualReleaseDate}
                governingSource={governingSourceForMaterial}
                componentLocation={componentLocation}
                isSubmittal={false}
                additionalMaterialOffset={
                  activeTemplateDetails?.additional_offset
                }
                projectTemplateMilestones={
                  activeTemplateDetails?.project_template_milestones
                }
              />
              <div className="w-40" />
            </div>
          </div>
        </div>
      ) : (
        <div>{noMaterialDateBlockDiv}</div>
      )}
    </div>
  );
}
export default DateBlockDynamicUIPanel;
