import { Popover } from "antd";
import TickIcon from "components/svg-icons/tick-icon";
import SubmittalLinksColLegend from "../svg-icons/submittal-links-col-legend";

function PopoverContent() {
  return (
    <div>
      <div>
        <SubmittalLinksColLegend />
      </div>
      <div className="mt-2 text-xs space-y-1">
        <div>
          1) <span className="italic">Submitted by Subcontractor Date</span>
        </div>
        <div>
          2) <span className="italic">Design Review Complete Date</span>
        </div>
        <div>
          3) <TickIcon /> <span className="italic">Milestone Completed</span>
        </div>
      </div>
    </div>
  );
}

function LinkedSubmittalColTooltip(params: any) {
  const { location } = params;
  if (location === "header") {
    return (
      <Popover placement="left" content={<PopoverContent />} defaultOpen>
        <div
          className="w-[50px]"
          style={{
            transform: "translate(-50%, -18px)"
          }}
        />
      </Popover>
    );
  }
  return null;
}

export default LinkedSubmittalColTooltip;
