import {
  ColDef,
  ColGroupDef,
  ColumnVisibleEvent,
  DragStoppedEvent,
  SideBarDef,
  ValueFormatterParams
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useMemo, useState } from "react";

import { getMilestoneColumns } from "components/milestones-columns";

import {
  ActionsCellRenderer,
  CellValueRendererWithEditIcon,
  IdLinkComponent,
  MaterialLinksCellRenderer,
  SubmittalStatusCellRenderer,
  TaskLinksCellRenderer,
  dateCellRenderer,
  dropDownCellRendererWithCustomEdit,
  dueDateCellRenderer,
  editSubmittalFloatCellRenderer,
  nextDeadlineCellRenderer,
  responsibleContractorCellRenderer,
  responsibleContractorCellRendererWithCustomEdit,
  riskCellRenderer,
  specSectionRendererWithFileViewer,
  submittalCellRenderer,
  submittalDueDateHeaderComponent,
  userOrgCellRenderer,
  userOrgCellRendererWithCustomEdit
} from "components/cell-renders";
import SubmittalTooltip from "pages/submittals-list/submittal-tooltip";
import { agGridCheckFieldEditable } from "components/submittal-details/helpers";
import { DateFilter, DateUtils } from "utils/dateutils";
import numberCellEditor from "components/cell-editor/numberCellEditor";

import { useMutation } from "@apollo/client";
import { TSaveColumnState } from "components/column-config-views";
import { message } from "antd";
import { MUTATION_SAVE_COLUMNS } from "services/graphQL/mutations";

import SpecSectionCellEditor from "components/cell-editor/spec-section-editor";
import { SelectCellEditor, UserSelectCellEditor } from "components/cell-editor";
import SelectNotFoundContent from "components/widgets/select-notfound-content";
import ResponsibleContractorCellEditor from "components/cell-editor/responsible-contractor-cell-editor";
import { SubmitterSelectCellEditor } from "components/cell-editor/submitter-select-cell-editor";
import { ESubmittalStatus, SubmittalStatusToStrMap } from "constants/index";
import CustomDateFilters from "../../submittals-list/custom-date-filter";
import { TSubmittalLogData } from "./ciq-submittals-log-data";

import {
  canChangeSubmittalDBFloat,
  getSubmittalColumnStateFromGrid,
  setFilterStateFromSubmittalData,
  submittalSequenceComparator
} from "../utils";

type TData = {
  columnDefs: (ColDef | ColGroupDef)[] | null;
  defaultColDef: ColDef;
  autoGroupColumnDef: ColDef;
  sideBar: SideBarDef | string | string[] | boolean | null;
};

const initialState = {
  columnDefs: null,
  defaultColDef: {
    sortable: true,
    filter: true,
    resizable: true,
    menuTabs: ["filterMenuTab"],
    sortingOrder: ["asc", "desc"],
    suppressColumnsToolPanel: false
  },
  autoGroupColumnDef: {
    headerName: "",
    field: "",
    cellRenderer: "agGroupCellRenderer",
    cellRendererParams: {
      checkbox: true
    }
  },
  sideBar: {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressSideButtons: true
        }
      }
    ]
  }
} as TData;

export const useSubmittalLogAgGrid = (
  gridRef: React.RefObject<AgGridReact<any>>,
  props: TSubmittalLogData
) => {
  const [hiddenColumns, setHiddenColumns] = useState<any[]>([]);

  const {
    submittalHeaderMap,
    isIntegrationMode,
    maxNumberOfMilestone,
    projectDetails,
    gqlClientForProject,
    savedColumnsData,
    projectId
  } = props;

  const [customDateFilter, setCustomDateFilter] = useState<any>({});

  const [saveColumnsState] = useMutation<any>(MUTATION_SAVE_COLUMNS, {
    client: gqlClientForProject
  });

  const milestonesColumns = useMemo(
    () =>
      getMilestoneColumns({
        setCustomDateFilter,
        totalPossibleMileStones: maxNumberOfMilestone.numberOfMaxMileStones
      }),
    [maxNumberOfMilestone.numberOfMaxMileStones]
  );

  const isVoidSubmittal = (params: any) => {
    const status = SubmittalStatusToStrMap[params?.data?.workflow_status];
    return status === SubmittalStatusToStrMap[ESubmittalStatus.VOID];
  };

  const isGridCellEditable = useCallback((params: any) => {
    return (
      !isVoidSubmittal(params) &&
      !params.context.isIntegrationMode &&
      agGridCheckFieldEditable(params)
    );
  }, []);

  const savedColumns = useMemo(() => {
    if (!savedColumnsData) return null;
    if (savedColumnsData?.project_users?.length === 0) return null;
    const resArr: any[] = savedColumnsData?.project_users;
    const colConfig = resArr[resArr.length - 1];
    return {
      submittals: colConfig.submittal_column_config
    };
  }, [savedColumnsData]);

  const columnDefs = useMemo(() => {
    if (
      !submittalHeaderMap ||
      !milestonesColumns.groupColDefMilestone ||
      !milestonesColumns.mileStoneColumns ||
      !savedColumns
    ) {
      return null;
    }
    const def = [
      {
        colId: "submittal_sequence_id",
        field: "submittal_sequence_id",
        headerName: submittalHeaderMap?.submittal_id?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.submittal_id?.toUpperCase() || "",
        pinned: "left",
        lockPosition: "left",
        showDisabledCheckboxes: true,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        width: 150,
        minWidth: 80,
        maxWidth: 200,
        filter: false,
        sortable: true,
        // sort: "asc",
        menuTabs: [],
        editable: false,
        cellRendererParams: {
          projectId
        },
        cellRenderer: IdLinkComponent,
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any) =>
          submittalSequenceComparator(
            valueA,
            valueB,
            nodeA,
            nodeB,
            projectDetails?.spec_section
          ),
        tooltipValueGetter: (params: any) => {
          const cellText =
            params.context.projectDetails?.spec_section && params.data.spec_no
              ? `${params.data.spec_no} - ${params.value}`
              : `${params.value}`;
          return cellText;
        },
        lockVisible: true
      },
      {
        colId: "submittal_number",
        field: "submittal_number",
        headerName: submittalHeaderMap?.submittal_number?.toUpperCase() || "",
        headerTooltip:
          submittalHeaderMap?.submittal_number?.toUpperCase() || "",
        width: 120,
        minWidth: 80,
        tooltipField: "submittal_number",
        editable: isGridCellEditable,
        cellRenderer: CellValueRendererWithEditIcon,
        cellClass: (params: any) => {
          return isGridCellEditable(params) ? "cell-editable" : "";
        },
        pinned: "left"
      },
      {
        colId: "title",
        field: "title",
        headerName: submittalHeaderMap?.title?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.title?.toUpperCase() || "",
        tooltipComponent: SubmittalTooltip,
        pinned: "left",
        filter: false,
        sortable: true,
        menuTabs: [],
        width: 140,
        minWidth: 140,
        maxWidth: 500,
        cellRenderer: submittalCellRenderer,
        cellRendererParams: {
          projectId
        },
        cellStyle: {
          overflow: "hidden",
          padding: 0
        },
        cellClass: (params: any) => {
          return isGridCellEditable(params) ? "cell-editable" : "";
        },
        comparator(valueA: any, valueB: any, nodeA: any, nodeB: any) {
          const compareA = nodeA.data.title ?? nodeA.data.description;
          const compareB = nodeB.data.title ?? nodeB.data.description;
          return compareA.toLowerCase().localeCompare(compareB.toLowerCase());
        },
        editable: isGridCellEditable,
        getQuickFilterText: (params: any) => {
          try {
            return `${params.data.title}\n${params.data.description}`;
          } catch (error) {
            return "";
          }
        },
        tooltipValueGetter: (params: any) => {
          try {
            const tooltip = `${params.data.title} - ${params.data.description}`;
            return tooltip;
          } catch (error) {
            return "";
          }
        },
        lockVisible: true
      },
      {
        colId: "description",
        field: "description",
        headerName: "DESCRIPTION",
        headerTooltip: "Description",
        editable: false,
        hide: true,
        suppressColumnsToolPanel: true,
        filter: false,
        menuTabs: [],
        pinned: "left",
        lockPosition: "left"
      },
      {
        colId: "status",
        field: "workflow_status",
        headerName: submittalHeaderMap?.workflow_status?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.workflow_status?.toUpperCase() || "",
        width: 250,
        minWidth: 100,
        maxWidth: 450,
        editable: false,
        cellEditorPopup: false,
        cellRenderer: SubmittalStatusCellRenderer,
        cellRendererParams: {
          dataMapVar: "statusesMap",
          valueVar: "id",
          labelVar: "name"
        },
        cellEditor: SelectCellEditor,
        cellEditorParams: {
          type: "regular-select",
          dataMapVar: "statusesMap",
          valueVar: "id",
          labelVar: "name"
        },
        // valueGetter: (params: any) => {
        //   return (
        //     getSubmittalStatus(params.data.history) || ESubmittalStatus.CREATE
        //   );
        // },
        keyCreator: (params: any) => {
          try {
            return params.context.statusesMap[params.value].name;
          } catch (error) {
            return "";
          }
        },
        getQuickFilterText: (params: any) => {
          try {
            return params.context.statusesMap[params.value].name;
          } catch (error) {
            return "";
          }
        },
        tooltipValueGetter: (params: any) => {
          return params.context.statusesMap[params.value].name;
        },
        lockVisible: true
      },
      {
        colId: "next_planned_deadline",
        field: "next_planned_deadline",
        sort: "asc",
        headerName:
          submittalHeaderMap?.next_planned_deadline?.toUpperCase() || "",
        headerTooltip:
          submittalHeaderMap?.next_planned_deadline?.toUpperCase() || "",
        width: 200,
        minWidth: 30,
        maxWidth: 220,
        editable: false,
        cellEditorPopup: false,
        cellRenderer: (params: any) => {
          return !isVoidSubmittal(params)
            ? nextDeadlineCellRenderer(params)
            : "";
        },
        comparator: DateFilter.comparator,
        filter: CustomDateFilters,
        filterParams: {
          title: submittalHeaderMap?.next_planned_deadline?.toUpperCase() || "",
          columnData: {
            field: "next_planned_deadline",
            header:
              submittalHeaderMap?.next_planned_deadline?.toUpperCase() || ""
          },
          setCustomDateFilter
        },
        getQuickFilterText: () => "",
        valueGetter: ({ data }: any) => {
          if (isVoidSubmittal({ data })) return "";
          return data.next_planned_deadline
            ? DateUtils.format(data.next_planned_deadline)
            : "";
        },
        tooltipValueGetter: (params: any) => params.value
      },
      {
        colId: "due_date",
        field: "due_date",
        headerName: submittalHeaderMap?.due_date?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.due_date?.toUpperCase() || "",
        headerComponentParams: submittalDueDateHeaderComponent,
        width: 200,
        minWidth: 30,
        maxWidth: 220,
        editable: false,
        cellEditorPopup: false,
        cellRenderer: (params: any) => {
          const isVoidSub = isVoidSubmittal(params);
          return !isVoidSub ? dueDateCellRenderer(params) : "";
        },
        comparator: DateFilter.comparator,
        menuTabs: ["filterMenuTab"],
        filter: CustomDateFilters,
        filterParams: {
          title: submittalHeaderMap?.due_date?.toUpperCase() || "",
          columnData: {
            field: "due_date",
            header: submittalHeaderMap?.due_date?.toUpperCase() || ""
          },
          setCustomDateFilter
        },
        getQuickFilterText: () => "",
        valueGetter: ({ data }: any) => {
          if (isVoidSubmittal({ data })) return "";
          return data?.due_date ? DateUtils.format(data?.due_date) : "";
        },
        tooltipValueGetter: (params: any) => params.value
      },
      {
        colId: "next_action",
        field: "next_action",
        headerName: submittalHeaderMap?.next_action?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.next_action?.toUpperCase() || "",
        width: 100,
        minWidth: 30,
        maxWidth: 120,
        editable: false,
        cellEditorPopup: false,
        tooltipField: "next_action",
        suppressColumnsToolPanel: true,
        getQuickFilterText: () => "",
        hide: true
      },
      {
        colId: "planned_float",
        field: "planned_float",
        headerName: "SUBMITTAL PLANNED FLOAT",
        headerTooltip: "SUBMITTAL PLANNED FLOAT",
        width: 130,
        minWidth: 30,
        maxWidth: 270,
        menuTabs: ["filterMenuTab"],
        editable: canChangeSubmittalDBFloat,
        cellEditorPopup: false,
        cellEditor: numberCellEditor,
        cellRenderer: editSubmittalFloatCellRenderer,
        valueGetter: (params: any) => {
          if (isVoidSubmittal(params)) return "";
          return params?.data?.planned_float;
        },
        tooltipValueGetter: (params: any) => params.value,
        filter: true,
        cellClass: (params: any) => {
          return canChangeSubmittalDBFloat(params) ? "cell-editable" : "";
        },
        getQuickFilterText: () => ""
      },
      {
        colId: "effective_float",
        field: "effective_float",
        headerName: "SUBMITTAL ACTUAL FLOAT",
        headerTooltip: "SUBMITTAL ACTUAL FLOAT",
        width: 130,
        minWidth: 30,
        maxWidth: 270,
        menuTabs: ["filterMenuTab"],
        editable: false,
        valueGetter: (params: any) => {
          if (isVoidSubmittal(params)) return "";
          return params?.data?.effective_float;
        },
        tooltipValueGetter: (params: any) => params.value,
        filter: true,
        getQuickFilterText: () => ""
      },
      {
        colId: "custom_spec_section",
        headerName: submittalHeaderMap?.spec_no?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.spec_no?.toUpperCase() || "",
        width: 200,
        minWidth: 100,
        maxWidth: 500,
        filter: "agSetColumnFilter",
        suppressMenu: false,
        menuTabs: ["filterMenuTab"],
        filterParams: {
          valueFormatter: (params: ValueFormatterParams) => params.value,
          showTooltips: true
        },
        sortable: true,
        editable: isGridCellEditable,
        cellEditor: SpecSectionCellEditor,
        cellEditorParams: {
          specNoField: "spec_no",
          specNameField: "spec_name",
          dataMapVar: "specNoMap"
        },
        cellRenderer: specSectionRendererWithFileViewer,
        cellRendererParams: {
          specNoField: "spec_no",
          specNameField: "spec_name",
          projectId
        },
        cellClass: (params: any) => {
          return isGridCellEditable(params) ? "cell-editable" : "";
        },
        valueGetter: (params: any) => {
          try {
            let specSectionStr = params.data.spec_no || "";
            if (params.data.spec_name) {
              specSectionStr += ` - ${params.data.spec_name}`;
            }
            return specSectionStr;
          } catch (ex) {
            return "";
          }
        },
        comparator: (valueA: string | null, valueB: string | null) => {
          if (!valueA) return 1;
          if (!valueB) return -1;
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
        tooltipValueGetter: ({ value }: any) => value
      },
      {
        colId: "revision",
        field: "revision",
        headerName: submittalHeaderMap?.revision?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.revision?.toUpperCase() || "",
        tooltipField: "revision",
        maxWidth: 120,
        lockVisible: true,
        getQuickFilterText: () => ""
      },
      {
        colId: "type",
        field: "type",
        headerName: submittalHeaderMap?.type?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.type?.toUpperCase() || "",
        width: 180,
        minWidth: 80,
        maxWidth: 250,
        editable: isGridCellEditable,
        cellEditorPopup: false,
        cellEditor: SelectCellEditor,
        cellRenderer: (params: any) => {
          return isIntegrationMode
            ? params?.value
            : dropDownCellRendererWithCustomEdit(params);
        },
        cellRendererParams: {
          dataMapVar: "typesMap",
          valueVar: "id",
          labelVar: "value"
        },
        cellEditorParams: {
          type: "regular-select",
          dataMapVar: "typesMap",
          valueVar: "id",
          labelVar: "value",
          showSearch: true
        },
        cellClass: (params: any) => {
          return isGridCellEditable(params) ? "cell-editable" : "";
        },
        keyCreator: (params: any) => params.value,
        getQuickFilterText: (params: any) => params.value,
        tooltipValueGetter: (params: any) => params.value,
        lockVisible: true
      },
      {
        colId: "state",
        field: "state",
        headerName: submittalHeaderMap?.state?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.state?.toUpperCase() || "",
        width: 100,
        minWidth: 30,
        maxWidth: 120,
        editable: false,
        cellEditorPopup: false,
        tooltipField: "state"
      },
      {
        colId: "assignee",
        // field: "submittal_assignee",
        headerName: submittalHeaderMap?.assignee?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.assignee?.toUpperCase() || "",
        width: 200,
        minWidth: 100,
        maxWidth: 300,
        editable: false,
        cellEditorPopup: false,
        // hide: isIntegrationMode,
        cellRenderer: (params: any) => {
          const data = userOrgCellRenderer(params);
          return data.cellMarkup;
        },
        cellRendererParams: {
          userInput: {
            id: "assignee_user_id",
            firstName: "assignee_first_name",
            lastName: "assignee_last_name",
            org: "assignee_org",
            unregistered_user: "assignee_unregistered",
            unregistered_user_org: "assignee_unregistered_org",
            statusKey: "assignee_status"
          }
        },
        valueGetter: (params: any) => {
          const data = userOrgCellRenderer(params);
          return data.fullName;
        },
        tooltipValueGetter: (params: any) => {
          const data = userOrgCellRenderer(params);
          return data.fullName;
        }
      },
      {
        colId: "gc_reviewer",
        field: "gc_reviewer_user_id",
        headerName: submittalHeaderMap?.gc_reviewer?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.gc_reviewer?.toUpperCase() || "",
        width: 200,
        minWidth: 100,
        maxWidth: 300,
        editable: isGridCellEditable,
        cellEditorPopup: false,
        // hide: isIntegrationMode,
        cellClass: (params: any) => {
          return isGridCellEditable(params) ? "cell-editable" : "";
        },
        cellRenderer: (params: any) => {
          const data = userOrgCellRendererWithCustomEdit(params);
          return data.cellMarkup;
        },
        cellRendererParams: {
          userInput: {
            id: "gc_reviewer_user_id",
            firstName: "gc_reviewer_first_name",
            lastName: "gc_reviewer_last_name",
            org: "gc_reviewer_org",
            statusKey: "gc_reviewer_status"
          }
        },
        cellEditor: UserSelectCellEditor,
        cellEditorParams: {
          type: "user-select",
          usersVar: "gcReviewers",
          notFoundContent: SelectNotFoundContent({
            notFoundMsg:
              "User does not exist in the project. Go to Project Settings to add new user.",
            onAddClick: () => {},
            showAddBtn: false
          })
        },
        valueGetter: (params: any) => {
          const data = userOrgCellRenderer(params);
          return data.fullName;
        },
        tooltipValueGetter: (params: any) => {
          const data = userOrgCellRenderer(params);
          return data.fullName;
        }
      },
      {
        colId: "design_reviewer",
        field: "design_reviewer_user_id",
        headerName: submittalHeaderMap?.design_reviewer?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.design_reviewer?.toUpperCase() || "",
        width: 200,
        minWidth: 100,
        maxWidth: 300,
        editable: isGridCellEditable,
        cellEditorPopup: false,
        // hide: isIntegrationMode,
        cellClass: (params: any) => {
          return isGridCellEditable(params) ? "cell-editable" : "";
        },
        cellRenderer: (params: any) => {
          const data = userOrgCellRendererWithCustomEdit(params);
          return data.cellMarkup;
        },
        cellRendererParams: {
          userInput: {
            id: "design_reviewer_user_id",
            firstName: "design_reviewer_first_name",
            lastName: "design_reviewer_last_name",
            org: "design_reviewer_org",
            unregistered_user: "design_reviewer_unregistered",
            unregistered_user_org: "design_reviewer_unregistered_org",
            statusKey: "design_reviewer_status"
          }
        },
        cellEditor: UserSelectCellEditor,
        cellEditorParams: {
          type: "user-select",
          usersVar: "designReviewers",
          notFoundContent: SelectNotFoundContent({
            notFoundMsg:
              "User does no exist in the project. Go to Project Settings to add new responsible contractor.",
            onAddClick: () => {},
            showAddBtn: false
          })
        },
        valueGetter: (params: any) => {
          const data = userOrgCellRenderer(params);
          return data.fullName;
        },
        tooltipValueGetter: (params: any) => {
          const data = userOrgCellRenderer(params);
          return data.fullName;
        }
      },
      {
        colId: "responsible_contractor",
        field: "responsible_contractor_id",
        headerName:
          submittalHeaderMap?.responsible_contractor?.toUpperCase() || "",
        headerTooltip:
          submittalHeaderMap?.responsible_contractor?.toUpperCase() || "",
        width: 200,
        minWidth: 100,
        maxWidth: 300,
        // autoHeight: true,
        editable: isGridCellEditable,
        cellEditorPopup: false,
        // hide: isIntegrationMode,
        cellClass: (params: any) => {
          return isGridCellEditable(params) ? "cell-editable" : "";
        },
        cellRenderer: (params: any) => {
          const data = responsibleContractorCellRendererWithCustomEdit(params);
          return data.cellMarkup;
        },
        cellEditor: ResponsibleContractorCellEditor,
        cellEditorParams: {
          type: "regular-select",
          showSearch: true,
          projectId
        },
        valueGetter: (params: any) => {
          const data = responsibleContractorCellRenderer(params);
          return data.orgName;
        },
        tooltipValueGetter: (params: any) => {
          const data = responsibleContractorCellRenderer(params);
          return data.orgName;
        }
      },
      {
        colId: "submitter",
        field: "submitter_user_id",
        headerName: submittalHeaderMap?.submitter?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.submitter?.toUpperCase() || "",
        width: 200,
        minWidth: 100,
        maxWidth: 300,
        editable: isGridCellEditable,
        cellEditorPopup: false,
        // hide: isIntegrationMode,
        cellClass: (params: any) => {
          return isGridCellEditable(params) ? "cell-editable" : "";
        },
        cellRenderer: (params: any) => {
          const data = userOrgCellRendererWithCustomEdit(params);
          return data.cellMarkup;
        },
        cellRendererParams: {
          userInput: {
            id: "submitter_user_id",
            firstName: "submitter_first_name",
            lastName: "submitter_last_name",
            org: "submitter_org",
            unregistered_user: "submitter_unregistered",
            unregistered_user_org: "responsible_contractor_name",
            statusKey: "submitter_status"
          }
        },
        cellEditor: SubmitterSelectCellEditor,
        cellEditorParams: {
          type: "user-select",
          projectId,
          notFoundContent: SelectNotFoundContent({
            notFoundMsg: "User not found. Go to Project Settings to add users",
            onAddClick: () => {},
            showAddBtn: false
          })
        },
        valueGetter: (params: any) => {
          const data = userOrgCellRenderer(params);
          return data.fullName;
        },
        tooltipValueGetter: (params: any) => {
          const data = userOrgCellRenderer(params);
          return data.fullName;
        }
      },
      {
        colId: "linked_tasks",
        field: "tasks",
        headerName: submittalHeaderMap?.linked_activities?.toUpperCase() || "",
        headerTooltip:
          submittalHeaderMap?.linked_activities?.toUpperCase() || "",
        filter: "agSetColumnFilter",
        menuTabs: ["filterMenuTab"],
        filterParams: {
          valueFormatter: (params: ValueFormatterParams) => {
            try {
              return params.value.length
                ? "Linked Activities Present"
                : "No Activities Linked";
            } catch {
              return "No Activities Linked";
            }
          }
        },
        width: 300,
        maxWidth: 500,
        cellRenderer: TaskLinksCellRenderer,
        getQuickFilterText: () => ""
      },
      {
        colId: "linked_materials",
        field: "materials",
        headerName: submittalHeaderMap?.linked_materials?.toUpperCase() || "",
        headerTooltip:
          submittalHeaderMap?.linked_materials?.toUpperCase() || "",
        filter: "agSetColumnFilter",
        menuTabs: ["filterMenuTab"],
        valueGetter: ({ data }: any) => {
          const realMaterials = data.materials?.filter((m: any) => !m.implicit);
          if (realMaterials?.length) return realMaterials;
          return null;
        },
        filterParams: {
          valueFormatter: (params: ValueFormatterParams) => {
            try {
              return params.value.length
                ? "Material Linked"
                : "No Materials Linked";
            } catch {
              return "No Materials Linked";
            }
          }
        },
        width: 300,
        maxWidth: 500,
        cellRenderer: MaterialLinksCellRenderer,
        getQuickFilterText: () => ""
      },
      {
        colId: "submittal_schedule_links",
        field: "submittal_schedule_links",
        headerName: "Action",
        headerTooltip: "ACTION",
        pinned: "right",
        width: 100,
        filter: false,
        menuTabs: [],
        cellRenderer: ActionsCellRenderer,
        suppressColumnsToolPanel: true,
        getQuickFilterText: () => ""
      },
      {
        colId: "planned_submittal_distributed_date",
        field: "planned_submittal_distributed_date",
        headerName: "SUBMITTAL DISTRIBUTED DATE (PLANNED)",
        headerTooltip: "Submittal Distributed Date (Planned)",
        hide: true,
        suppressColumnsToolPanel: true,
        width: 150,
        minWidth: 70,
        maxWidth: 250,
        editable: false,
        cellRenderer: dateCellRenderer,
        comparator: DateFilter.comparator,
        filter: CustomDateFilters,
        filterParams: {
          title: "SUBMITTAL DISTRIBUTED DATE (PLANNED)",
          columnData: {
            field: "planned_submittal_distributed_date",
            header: "SUBMITTAL DISTRIBUTED DATE (PLANNED)"
          },
          setCustomDateFilter
        },
        getQuickFilterText: () => ""
      },
      {
        colId: "planned_trade_partner_submitted_date",
        field: "planned_trade_partner_submitted_date",
        headerName: "SUBMITTED BY SUBCONTRACTOR (PLANNED)",
        headerTooltip: "Submitted By SUBCONTRACTOR (Planned)",
        width: 150,
        minWidth: 70,
        maxWidth: 350,
        editable: false,
        hide: true,
        suppressColumnsToolPanel: true,
        cellRenderer: dateCellRenderer,
        comparator: DateFilter.comparator,
        filter: CustomDateFilters,
        filterParams: {
          title: "SUBMITTED BY SUBCONTRACTOR (ACTUAL)",
          columnData: {
            field: "planned_trade_partner_submitted_date",
            header: "SUBMITTED BY SUBCONTRACTOR (PLANNED)"
          },
          setCustomDateFilter
        },
        getQuickFilterText: () => ""
      },
      {
        colId: "planned_ext_review_completed_date",
        field: "planned_ext_review_completed_date",
        headerName: "EXTERNAL REVIEW COMPLETE (PLANNED)",
        headerTooltip: "External Review Complete (Planned)",
        width: 150,
        minWidth: 70,
        maxWidth: 300,
        editable: false,
        hide: true,
        suppressColumnsToolPanel: true,
        cellRenderer: dateCellRenderer,
        comparator: DateFilter.comparator,
        filter: CustomDateFilters,
        filterParams: {
          title: "EXTERNAL REVIEW COMPLETE (PLANNED)",
          columnData: {
            field: "actual_ext_review_completed_date",
            header: "EXTERNAL REVIEW COMPLETE (PLANNED)"
          },
          setCustomDateFilter
        },
        getQuickFilterText: () => ""
      },
      {
        colId: "submittal_created_by",
        headerName: "CREATED BY",
        headerTooltip: "CREATED BY",
        hide: true,
        suppressColumnsToolPanel: true,
        width: 150,
        minWidth: 70,
        maxWidth: 300,
        editable: false,
        valueGetter: ({ data }: any) => {
          try {
            return `${data.created_by_first_name} ${data.created_by_last_name}`;
          } catch (ex) {
            return "";
          }
        },
        getQuickFilterText: () => ""
      },
      {
        colId: "risk_level",
        field: "risk_level",
        headerName: submittalHeaderMap?.risk_level?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.risk_level?.toUpperCase() || "",
        width: 100,
        minWidth: 30,
        maxWidth: 120,
        editable: false,
        cellEditorPopup: false,
        valueGetter: (params: any) => {
          if (isVoidSubmittal(params)) return "";
          return params?.data?.risk_level;
        },
        tooltipValueGetter: (params: any) => params.value,
        cellRenderer: riskCellRenderer
      },
      {
        colId: "priority",
        field: "priority",
        headerName: submittalHeaderMap?.priority?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.priority?.toUpperCase() || "",
        width: 180,
        minWidth: 60,
        maxWidth: 200,
        tooltipField: "priority",
        editable: isGridCellEditable,
        cellRenderer: dropDownCellRendererWithCustomEdit,
        cellClass: (params: any) => {
          return isGridCellEditable(params) ? "cell-editable" : "";
        },
        cellEditor: SelectCellEditor,
        cellEditorParams: {
          type: "regular-select",
          dataMapVar: "priorities",
          valueVar: "value",
          labelVar: "label",
          showSearch: false,
          isSorting: false
        },
        cellRendererParams: {
          dataMapVar: "priorities",
          valueVar: "value",
          labelVar: "label"
        },
        getQuickFilterText: () => ""
      },
      {
        colId: "watchers",
        field: "watchers",
        headerName: submittalHeaderMap?.watchers?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.watchers?.toUpperCase() || "",
        width: 120,
        minWidth: 80,
        tooltipField: "watchers",
        suppressColumnsToolPanel: false,
        editable: isGridCellEditable,
        cellRenderer: CellValueRendererWithEditIcon,
        cellClass: (params: any) => {
          return isGridCellEditable(params) ? "cell-editable" : "";
        },
        getQuickFilterText: () => ""
      },
      {
        colId: "created_at",
        field: "created_at",
        headerName: submittalHeaderMap?.created_at?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.created_at?.toUpperCase() || "",
        width: 200,
        minWidth: 30,
        maxWidth: 220,
        editable: false,
        cellEditorPopup: false,
        comparator: DateFilter.comparator,
        filter: CustomDateFilters,
        filterParams: {
          title: submittalHeaderMap?.created_at?.toUpperCase() || "",
          columnData: {
            field: "created_at",
            header: submittalHeaderMap?.created_at?.toUpperCase() || ""
          },
          setCustomDateFilter
        },
        getQuickFilterText: () => "",
        tooltipValueGetter: (params: any) => {
          return DateUtils.format(params.value);
        },
        tooltipField: "created_at",
        suppressColumnsToolPanel: true,
        hide: true
      },

      ...milestonesColumns.groupColDefMilestone,
      ...milestonesColumns.mileStoneColumns
    ] as (ColDef | ColGroupDef)[];
    return def;
  }, [
    isIntegrationMode,
    projectId,
    projectDetails?.spec_section,
    submittalHeaderMap,
    milestonesColumns.groupColDefMilestone,
    milestonesColumns.mileStoneColumns,
    savedColumns,
    isGridCellEditable
  ]);

  const skippedColumns = useMemo(() => {
    const milsStoneColumnColIds = milestonesColumns.mileStoneColumns.map(
      (x) => x.colId
    );
    return [
      "planned_submittal_distributed_date",
      "planned_trade_partner_submitted_date",
      "planned_ext_review_completed_date",
      "submittal_created_by",
      "submittal_schedule_links",
      ...milsStoneColumnColIds
    ];
  }, [milestonesColumns.mileStoneColumns]);

  const getColumnStateFromGrid = () => {
    if (gridRef.current) {
      return getSubmittalColumnStateFromGrid({
        api: gridRef.current.api,
        columnApi: gridRef.current.columnApi,
        skippedColumns
      });
    }
    return null;
  };

  const setFilterStateFromData = (columnState: TSaveColumnState) => {
    if (gridRef.current) {
      gridRef?.current?.api?.setFilterModel(null);
      setFilterStateFromSubmittalData({
        submittalColumns: columnState,
        skippedColumns,
        api: gridRef.current.api,
        columnApi: gridRef.current.columnApi
      });
    }
  };

  const onSaveColumnState = async () => {
    const columnsStates = getColumnStateFromGrid();
    if (columnsStates) {
      try {
        const variables = {
          submittal_column_config: columnsStates
        };
        const updateResponse: any = await saveColumnsState({ variables });
        if (updateResponse.errors) {
          message.error({ content: updateResponse.errors[0].message });
        } else {
          // message.success("Updated successfully");
        }
      } catch (ex) {
        console.log("An error occured");
      }
    }
  };

  const onColumnVisible = (e: ColumnVisibleEvent) => {
    const { columnApi } = e;
    const hiddenCols =
      columnApi
        .getColumns()
        ?.filter((column: any) => !column.visible)
        .map((col: any) => col.colId) || [];

    setHiddenColumns([...hiddenCols, "submittal_schedule_links"]);

    if (columnApi && e.source === "toolPanelUi") {
      onSaveColumnState();
      if (e.column) {
        milestonesColumns.onColumnVisible(e, maxNumberOfMilestone);
      } else {
        milestonesColumns.updateAllMileStoneInitialVisibility(
          e.columnApi,
          maxNumberOfMilestone
        );
      }
    }
  };

  const onDragStopped = (e: DragStoppedEvent) => {
    const classNames = [
      "ag-header-cell-resize",
      "ag-tool-panel-horizontal-resize"
    ];
    if (e.columnApi && !classNames.includes(e.target.className)) {
      onSaveColumnState();
    }
  };

  const onEditCell = useCallback(
    (params: any) => {
      gridRef?.current?.api?.setFocusedCell(params.rowIndex, params.colKey);
      gridRef?.current?.api?.startEditingCell({
        rowIndex: params.rowIndex,
        colKey: params.colKey
      });
    },
    [gridRef]
  );

  return {
    ...initialState,
    columnDefs,
    onColumnVisible,
    onDragStopped,
    onEditCell,
    milestonesColumns,
    hiddenColumns,
    skippedColumns,
    savedColumns,
    customDateFilter,
    setCustomDateFilter,
    getColumnStateFromGrid,
    setFilterStateFromData,
    onSaveColumnState
  };
};
